const serviceModals = document.querySelectorAll(".js-service-modal");


const toggleServiceModals = () => {
  serviceModals.forEach((modal) => {
    modal
      .querySelector(".js-service-modal-button")
      .addEventListener("click", function () {
        modal.setAttribute("aria-hidden", "true");
        modal.classList.remove("is-open");
      });
  });
};


export default toggleServiceModals;