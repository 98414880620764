import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";

const initResearchSwiper = function () {
  new Swiper(".js-research-swiper", {
    slidesPerView: 1,
    spaceBetween: 12,
    pagination: {
      el: ".js-research-pagination",
      type: "bullets",
    },
  });
};

export default initResearchSwiper;