import jQuery from 'jquery'
import MicroModal from "micromodal";

import initYandexMap from "./js/initYandexMap";
import initServicesSwiper from "./js/initServicesSwiper";
import initResearchSwiper from "./js/initResearchSwiper";
import initAdvantagesSwiper from "./js/initAdvantagesSwiper";
import initClientsSwiper from "./js/initClientsSwiper";
import initTeamSwiper from "./js/initTeamSwiper";
import toggleHeaderMenu from "./js/toggleHeaderMenu";
import initSectionsPagination from './js/initSectionsPagination';
import toggleFaqItems from "./js/toggleFaqItems";
import toggleServiceModals from './js/toggleServiceModals';

window.$ = window.jquery = window.jQuery = jQuery

document.addEventListener("DOMContentLoaded", function () {
  initYandexMap();
  initServicesSwiper();
  initResearchSwiper();
  initAdvantagesSwiper();
  initClientsSwiper();
  initTeamSwiper();
  toggleHeaderMenu();
  toggleFaqItems();
  MicroModal.init();
  initSectionsPagination();
  toggleServiceModals();
});


