import inView from "in-view";


const introBullet = document.querySelector(
  ".js-sections-pagination__bullet--intro"
);
const servicesBullet = document.querySelector(
  ".js-sections-pagination__bullet--services"
);
const researchBullet = document.querySelector(
  ".js-sections-pagination__bullet--research"
);
const advantagesBullet = document.querySelector(
  ".js-sections-pagination__bullet--advantages"
);
const videoplayerBullet = document.querySelector(
  ".js-sections-pagination__bullet--videoplayer"
);
const formBullet = document.querySelector(
  ".js-sections-pagination__bullet--form"
);
const clientsBullet = document.querySelector(
  ".js-sections-pagination__bullet--clients"
);
const teamBullet = document.querySelector(
  ".js-sections-pagination__bullet--team"
);
const faqBullet = document.querySelector(
  ".js-sections-pagination__bullet--faq"
);
const contactsBullet = document.querySelector(
  ".js-sections-pagination__bullet--contacts"
);
const bullets = document.querySelectorAll(".js-sections-pagination__bullet");

const inViewSection = (sectionSelector, bullet) => {
  inView(sectionSelector)
    .on("enter", function () {
      bullets.forEach((el) =>
        el.classList.remove("js-sections-pagination__bullet--active")
      );
      bullet.classList.add("js-sections-pagination__bullet--active");
    })
    .on("exit", function () {
      bullet.classList.remove("js-sections-pagination__bullet--active");
    });
};

const initSectionsPagination = () => {
  inViewSection("._intro", introBullet);
  inViewSection("._services", servicesBullet);
  inViewSection("._research", researchBullet);
  inViewSection("._advantages", advantagesBullet);
  inViewSection("._videoplayer", videoplayerBullet);
  inViewSection("._form", formBullet);
  inViewSection("._clients", clientsBullet);
  inViewSection("._team", teamBullet);
  inViewSection("._faq", faqBullet);
  inViewSection("._contacts", contactsBullet);
};

export default initSectionsPagination;