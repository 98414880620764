import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";

const initClientsSwiper = function () {
  new Swiper(".js-clients-swiper", {
    slidesPerView: 1,
    spaceBetween: 12,
    navigation: {
      nextEl: ".clients-swiper__button--next",
      prevEl: ".clients-swiper__button--prev",
    },
    autoHeight: true,
  });
};

export default initClientsSwiper;
