import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";

const initAdvantagesSwiper = function () {
  new Swiper(".js-advantages-swiper", {
    slidesPerView: 2,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".advantages-swiper__button--next",
      prevEl: ".advantages-swiper__button--prev",
    },
  });
};

export default initAdvantagesSwiper;