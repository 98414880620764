const faqItems = document.querySelectorAll(".js-faq-item");
const faqButtons = document.querySelectorAll(".js-faq-item-button");

const toggleFaqItems = function () {
  if (faqItems && faqButtons) {
    const switchFaqItem = (e) => {
      let activeButtonNumber = e.currentTarget.getAttribute("data-faq-button");

      faqItems.forEach((faqItem, index) => {
        let faqItemNumber = faqItem.getAttribute("data-faq-item");
        if (activeButtonNumber === faqItemNumber) {
          faqItem.classList.toggle("js-faq-item-active");
        }
      });
    };

    faqButtons.forEach((button) => {
      button.addEventListener("click", switchFaqItem);
    });
  }
};

export default toggleFaqItems;